import axios from "axios";
import authHeader from "../../auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL;

class VideoService {
  
  getVideos(page, limit, query) {
    if (query) {
      return axios.get(API_URL + `admin/videos?page=${page}&limit=${limit}&q=${query}`, { headers: authHeader() });
    } else {
      return axios.get(API_URL + `admin/videos?page=${page}&limit=${limit}`, { headers: authHeader() });
    }
  }

  async getTags() {
    return axios.get(API_URL + `tags`, {
      headers: authHeader(),
    });
  }

  get(id) {
    return axios.get(API_URL + `admin/videos/${id}`, { headers: authHeader() });
  }

  create(data) {
    return axios.post(API_URL + `admin/videos/`, data, { headers: authHeader() });
  }

  update(data, tags) {
    return axios.put(API_URL + `admin/videos/${data.id}`, { ...data, tags: tags }, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(API_URL + `admin/videos/${id}`, { headers: authHeader() });
  }

}

export default new VideoService();
